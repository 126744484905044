import React from 'react';
import "./assets/css/style.css";
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Home() {
  const handleClickScroll = () => {
    let element = document.getElementById("aboutme");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return(
    <> 
    <Helmet>
      <title>Jose Upmind Digital Optima</title>
    </Helmet>
    <div className='container-fluid background-hero'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper-logo-jose'>
              <LazyLoadImage 
              className='img img-fluid'
              src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Logo-JOSE-UPMIND.png'   alt='logo joseupmind'
              effect="blur"
              />
            </div>
          </div>
        </div>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
            <div className='wrapper-image-section-1'>
              <LazyLoadImage 
                className='img img-fluid' 
                src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/Image.png' 
                alt='img section 1'
                effect="blur"
                />
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
            <div className='wrapper-text-side py-5'>
              <LazyLoadImage 
                className='img img-fluid w-100' 
                effect="blur"
                src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/text.svg' 
                alt='text'/>
              <p className='text-center text-white'>
                Kami adalah perusahaan yang fokus pada pengembangan
                diri agar wanita Indonesia lebih mudah jadi happy
              </p>
              <button 
                className='btn btn-primary btn-lg btn-block btn-custom w-100'
                onClick={handleClickScroll}
                >
                Tentang Kami
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid margin-custom-top-100'>
      <div className='container'>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <h4 className='text-left font-custom-heading-content'>
              Service
            </h4>
          </div>
        </div>
        <div className='row my-4 gap-0'>
          <div className='col-lg-3 col-md-3 col-xs-12 col-12'>
            <div className="card w-100 no-border-card">
              <LazyLoadImage 
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Platform-Thumbnail.png" 
                className="card-img-top" 
                alt="paltform"
                effect="blur"
              />
              <div className="card-body background-grey">
                <p className="card-text">
                  Bertumbuh bersama dengan komunitas
                </p>
                <button 
                  className="btn btn-primary button-card-custom"
                  onClick={() => {
                    window.location.replace('https://trulav.id/');
                  }}
                  >
                    Pelajari
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-xs-12 col-12'>
            <div className="card w-100 no-border-card">
              <LazyLoadImage 
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Offline-Thumbnail.png" 
                className="card-img-top" 
                alt="paltform"
                effect="blur"
                />
              <div className="card-body background-grey">
                <p className="card-text">
                  Bertumbuh bersama dengan offline training
                </p>
                <button 
                  className="btn btn-primary button-card-custom"
                  onClick={() => {
                    window.location.replace('https://lovetraining.id/');
                  }}
                  >
                    Pelajari
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-xs-12 col-12'>
            <div className="card w-100 no-border-card">
              <LazyLoadImage 
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Coaching-Thumbnail.png" 
                className="card-img-top" 
                alt="paltform"
                effect="blur"
              />
              <div className="card-body background-grey">
                <p className="card-text">
                  Pertumbuhan diri dengan coach terbaik
                </p>
                <button 
                  className="btn btn-primary button-card-custom"
                  onClick={() => {
                    window.location.replace('https://lovecoach.id/');
                  }}
                  >
                    Pelajari
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-xs-12 col-12'>
            <div className="card w-100 no-border-card h-100">
              <LazyLoadImage 
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Book-Thumbnail.png" 
                className="card-img-top" 
                alt="paltform"
                effect="blur"
              />
              <div className="card-body background-grey">
                <p className="card-text">
                  Buku terbaru untuk diri yang baru
                </p>
                <button 
                  className="btn btn-primary button-card-custom disabled"
                
                  >
                    Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container margin-custom-top-100' id='aboutme'>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <h4 className='text-left font-custom-heading-content'>
              Why We Exist
            </h4>
          </div>
        </div>
        <div className='row my-5 justify-content-center align-items-center'>
          <div className='col-lg-4 col-md-4 col-xs-12 col-12'>
            <div className='wrappe-logo-full-color'>
              <LazyLoadImage 
              className='img img-fluid' 
              src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/logo-full-color-JOSE-UPMIND.png' 
              alt='logo full color'
              effect="blur"
              />
            </div>
          </div>
          <div className='col-lg-8 col-md-8 col-xs-12 col-12'>
            <div className='wrapper-desc-why-exist'>
              <p>
                <u>Kami ingin jadi penentu utama dibalik kebahagiaan romansa setiap manusia yang hidup di Indonesia.</u> Karena bahagia bukan hanya tentang tertawa dan kesenangan semata, tapi juga tentang pertumbuhan diri dan dampak bagi sesama.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid background-image-brand mb-5 margin-custom-top-100'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
            <p className='font-size-custom p-0 m-0'>Brand Kami</p>
          </div>
          <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
            <div className='wrapper-logo-brand'>
              <LazyLoadImage 
              src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/tr-logo.svg' 
              alt='logo trulav'
              effect="blur"
              />
              <LazyLoadImage 
              src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/LOVECOACH-LOGO.svg' 
              alt='logo lovecoach'
              effect="blur"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='conatiner-fluid margin-custom-top-100'>
      <div className='container'>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <h4 className='text-left font-custom-heading-content'>
              Tentang Kami
            </h4>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-7 col-md-7 col-xs-12 col-12'>
            <div className='row align-items-center'>
              <div className='col-sm-4 col-md-4'>
                <div className="card no-border-card my-3">
                  <div className="card-body background-grey-card border-radius-20">
                   <h3 className='custom-heading-about-me'>
                      97.528+
                   </h3>
                   <p className='custom-text-about-me'>
                      PARTISIPAN
                   </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-md-4'>
                <div className="card no-border-card my-3">
                  <div className="card-body background-grey-card border-radius-20">
                   <h3 className='custom-heading-about-me'>
                      15.613+
                   </h3>
                   <p className='custom-text-about-me'>
                      ALUMNI
                   </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-md-4'>
                <div className="card no-border-card my-3">
                  <div className="card-body background-grey-card border-radius-20">
                   <h3 className='custom-heading-about-me'>
                      420+
                   </h3>
                   <p className='custom-text-about-me'>
                      BERHASIL MENIKAH
                   </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-md-4'>
                <div className="card no-border-card my-3">
                  <div className="card-body background-grey-card border-radius-20">
                   <h3 className='custom-heading-about-me'>
                      22.837+
                   </h3>
                   <p className='custom-text-about-me'>
                      JUMLAH MEMBER
                   </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-md-4'>
                <div className="card no-border-card my-3">
                  <div className="card-body background-grey-card border-radius-20">
                   <h3 className='custom-heading-about-me'>
                      69
                   </h3>
                   <p className='custom-text-about-me'>
                      ONLINE & OFFLINE EVENT
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-5 col-md-5 col-xs-12 col-12'>
            <div className='wrapper-image-career'>
              <LazyLoadImage 
              className='img img-fluid' 
              src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/career-woman.png' 
              alt='image career2'
              effect="blur"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid background-footer'>
      <div className='container'>
        <div className='row mt-5 justify-content-between align-items-center'>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-sm-4'>
                <LazyLoadImage 
                className='img img-fluid' 
                src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/logo-upmind-jose.png'  
                alt='logo joseupmind'
                effect="blur"
                />
              </div>
              <div className='col-sm-8'>
                <div className='wrapper-text-address'>
                  <p className='text-white'>
                  PT. Jose Upmind Digital Optima
                  Permata Regency D/37, Jl H. Kelik RT 1 / RW 6, Srengseng,
                  Kembangan, Jakarta Barat 11630
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-sm-4'>
                <h3 className='text-white'>
                  Contact Us
                </h3>
                <p className='text-white font-size-custom-12' 
                onClick={() => {
                  window.location = 'mailto:management@joseupmind.com';
                }} >
                  Email: <u>management@joseupmind.com</u>
                </p>
              </div>
              <div className='col-sm-4'>
                <h3 className='text-white'>
                  Join Us
                </h3>
                <p className='text-white font-size-custom-12'
                         onClick={()=>{
                            window.location.replace('https://joseupmind.com/karir');
                        }}
                        >
                            <u>Halaman Karir</u>
                        </p>
              </div>
              <div className='col-sm-4'>
                <div className='wrapper-sosial-media'>
                  <LazyLoadImage 
                  src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/FB-icon.svg' 
                  alt='facebook'
                  effect="blur"
                  onClick={()=>{
                    window.location.replace('https://web.facebook.com/trulavofficial/');
                  }}
                  />
                  <LazyLoadImage 
                  src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/IG-icon.svg' 
                  alt='instagram'
                  effect="blur"
                  onClick={()=>{
                    window.location.replace('https://www.instagram.com/trulav.id/');
                  }}
                  />
                  <LazyLoadImage 
                  src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/YT-icon.svg' 
                  alt='youtube'
                  effect="blur"
                  onClick={()=>{
                    window.location.replace('https://www.youtube.com/@JoseAditya');
                  }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-12'>
            <p className='text-center text-white font-footer'>
              Copyright © 2023 PT Jose Upmind Digital Optima
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
